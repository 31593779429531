import { useAuth } from '@components/Auth';
import VnrLink from '@components/VnrLink';
import { getAllBannerHomeSlider } from '@services/bannerAds.service';
import React, { Fragment, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import Slider from 'react-slick';
import { LOGIN_STATUS } from 'src/constants';

import BannerHome from './BannerHome';
import styles from './HomeTopSlider.module.scss';

const HomeTopSlider = () => {
  const { signed } = useAuth();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const callGetBannerShow = async () => {
    const res = await getAllBannerHomeSlider();
    if (!res.data || !res.data.content || !res.data.content[0]) return;
    const listBanner = res.data.content;
    setData(listBanner);
  };

  useEffect(() => {
    const time = isMobile ? 6000 : 1000;
    const timeoutBanner = setTimeout(() => {
      callGetBannerShow();
    }, time);

    return () => {
      clearTimeout(timeoutBanner);
    };
  }, []);

  const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: 'block', background: 'transparent' }}
        onClick={onClick}
      />
    );
  };

  const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: 'block', background: 'transparent' }}
        onClick={onClick}
      />
    );
  };

  const paging = (i) => <div className={styles.dotsSlider}>{i + 1}</div>;

  const renderBannerRebate = (item) => {
    if (signed !== LOGIN_STATUS.LOGGED_IN) {
      return (
        <div
          onClick={() => {
            dispatch.app.showPopupLogin({ positionLogin: 'BANNER_SLIDER' });
          }}
          key={item.id}
          id={`home_top_slider_${item.id}`}
          className="banner-ads bannervnrebate"
        >
          <img src={item.imageUrl} alt={item.code} className={styles.imageBanner} />
        </div>
      );
    } else {
      return (
        <div key={item.id} id={`home_top_slider_${item.id}`}>
          <VnrLink id={item.code} to={item.url} className="banner-ads bannervnrebate">
            <img src={item.imageUrl} alt={item.code} className={styles.imageBanner} />
          </VnrLink>
        </div>
      );
    }
  };

  const settingSlider = {
    dots: true,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 7000,
    nextArrow: <NextArrow className={styles.nextArrow} />,
    prevArrow: <PrevArrow className={styles.prevArrow} />,
    customPaging: paging,
  };

  let refSlider = null;

  useEffect(() => {
    if (!data || data.length === 0) {
      return;
    }
    const timeout = setTimeout(() => {
      if (refSlider) {
        refSlider.slickGoTo(1);
      }
    }, 10000);
    return () => {
      clearTimeout(timeout);
    };
  }, [refSlider, data]);

  return (
    <Fragment>
      <Slider
        ref={(slider) => (refSlider = slider)}
        {...settingSlider}
        className={styles['vnrb-homeTopSlider']}
      >
        <VnrLink to="https://t.me/vnrebatessupport">
          <BannerHome />
        </VnrLink>
        {data.map((item) => {
          if (item.code === 'home_top_slider_1717729421394') {
            return renderBannerRebate(item);
          }

          return (
            <div key={item.id} id={`home_top_slider_${item.id}`}>
              <div>
                <VnrLink id={item.code} to={item.url} className="banner-ads bannervnrebate">
                  <img src={item.imageUrl} alt={item.code} className={styles.imageBanner} />
                </VnrLink>
              </div>
            </div>
          );
        })}
      </Slider>
    </Fragment>
  );
};

export default HomeTopSlider;
