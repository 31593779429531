import Image from 'next/image';
import React from 'react';

const BannerHome = () => {
  return (
    <Image
      priority={true}
      width={770}
      height={390}
      alt="HomeBanner"
      quality={100}
      src="/images/1716862789107-hoan_tien_40pt_770x390.webp"
    ></Image>
  );
};

export default BannerHome;
